<template>
  <Page>
    <span slot="title">Budget overzicht</span>
    <span slot="actionbar">
      <div class="btn-group" style="width: 36%">
          <select class="form-control" v-model="year" style="display: inline-block; width: 200px">
            <option value="2024">2024</option>
            <option value="2023">2023</option>
            <option value="2022">2022</option>
            <option value="2021">2021</option>
            <option value="2020">2020</option>
            <option value="2019">2019</option>
          </select>
          <a class="btn btn-default" href="/#/budget/invoices" v-if="user.is_staff">Facturatie</a>
          <button class="btn btn-default dropdown-toggle" data-toggle="dropdown" v-if="user.is_staff">
            Rapporten <span class="caret"></span>
          </button>
          <ul class="dropdown-menu">
            <li><router-link :to="{name: 'UsageReport'}">Verbruik</router-link></li>
          </ul>
      </div>
    </span>

    <div>
      <div v-if="viewType === 'table'">
        <div :key="budget.id" v-for="budget in budgets">
          <BudgetOverviewPanel :budget="budget" />
        </div>
      </div>
      <div v-if="viewType === 'graph'">
        <div :key="budget.id" class="row" v-for="budget in budgets">
          <div class="col-sm-12">
            <div class="ibox">
              <div class="ibox-content">
                <div>
                  <h3 class="font-bold no-margins">
                    <router-link :to="{name: 'Budget', params: {id: budget.id}}">Marketing budget {{ budget.year }} voor
                      kantoor {{ budget.office.city }}
                    </router-link>
                  </h3>
                </div>

                <div class="m-t-sm">
                  <BudgetOverviewPanel :budget="budget" />
                </div>

                <div class="m-t-md">
                  <small class="float-right">
                    <i class="fa fa-clock-o"> </i>
                    Update on 16.07.2015
                  </small>
                  <small>
                  </small>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Page>
</template>

<script>
import BudgetOverviewPanel from '@/components/budget/BudgetOverviewPanel'
import Page from '@/components/iam/Page'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'BudgetList',
  components: { Page, BudgetOverviewPanel },
  data () {
    return {
      viewType: 'table',
      visibleBudgetDetails: {},
      year: 2024
    }
  },
  watch: {
    year: {
      handler (val) {
        this.loadBudgets(val)
      },
      immediate: true
    }
  },
  computed: {
    ...mapState('account', ['user']),
    ...mapGetters('budget', ['getBudgets']),
    budgets () {
      return this.getBudgets()
    }
  },
  methods: {
    ...mapActions('budget', ['loadBudgets']),
    toggleBudget (budgetId) {
      if (budgetId in this.visibleBudgetDetails) {
        delete this.visibleBudgetDetails[budgetId]
      } else {
        this.visibleBudgetDetails[budgetId] = true
      }
    },
    isVisible (budgetId) {
      return budgetId in this.visibleBudgetDetails
    }
  }
}
</script>

<style scoped>
  th.txt-danger {
    color: red;
  }
</style>
